<template>
	<el-card  style="background: #fff; height: 100%;">
		<div slot="header" class="tit">
			<h3 class="inBlock">系统设置</h3>
			
			<el-button style="float: right;" type="primary" @click="add">增加</el-button>
		</div>
		  <el-tabs v-model="activeName" @tab-click="handleClick">
		    <el-tab-pane v-for="(item,index) in tabs" :label="item.group_name" :key="index" :name="item.group">
				<el-form style="width: 100%; margin: auto;" ref="form" :model="form" label-width="300px">
				
				  <el-form-item v-for="(items,i) in Systemsetting_list" :key="i" :label="items.title+'('+items.tip+')'">
					  <div v-if="items.type=='radio'">
						  <el-radio @change="radio_click(items.name,items.value,'1',items.id,items.Changeover)" v-model="form[items.name]" label="1" >开启</el-radio>
						  <el-radio @change="radio_click(items.name,items.value,'2',items.id,items.Changeover)" v-model="form[items.name]" label="2">关闭</el-radio>
						<el-button type="primary" @click="bianji(i)">编辑</el-button>	
					  </div>
					  <div v-else>
						  <el-input style="width: 500px;" show-password v-if="items.type=='password'" @input="shuru(form[items.name],items.name,items.value,items.id)" v-model="form[items.name]"></el-input>
						  <el-input style="width: 500px;" v-else @input="shuru(form[items.name],items.name,items.value,items.id)" v-model="form[items.name]"></el-input>
						  <el-button type="primary" @click="bianji(i)">编辑</el-button>
					  </div>		
				  </el-form-item>
				  <!-- <el-form-item label="上传地址">
				    <el-input v-model="form.images_url"></el-input>
				  </el-form-item>
				  <el-form-item label="订单超时">
				    <el-input v-model="form.time_out"></el-input>
				  </el-form-item>
				  <el-form-item label="小程序审核状态">
					    <el-radio v-model="form.mini_examine" :label="1" >开启</el-radio>
					    <el-radio  v-model="form.mini_examine" :label="2">关闭</el-radio>
				  </el-form-item> -->
				  
				  
				  <el-form-item>
				    <el-button type="primary" @click="onSubmit">确定</el-button>
					
				  </el-form-item>
				</el-form>
			</el-tab-pane>
		  </el-tabs>
		  <el-dialog
		    :title="is_type==1?'增加系统配置':'编辑系统配置'"
		    :visible.sync="dialogVisible"
		    width="30%"
		    >
		    <el-form ref="form" :model="formdata" label-width="120px">
			<el-form-item :label="is_show==false?'选择分组':'分组变量'">
			    <el-select
				:disabled="is_type==2?true:false"
				v-if="!is_show"
			      v-model="formdata.group"
				  @change="change"
			      placeholder="请选择分组">
			      <el-option
			        v-for="item in options"
			        :key="item.group"
			        :label="item.group_name"
			        :value="item.group">
			      </el-option>
			    </el-select>
				<el-input style="width: 200px;" v-model="formdata.group" v-if="is_show" placeholder="请输入分组变量">
					
				</el-input>
				<span :hidden="is_type==2?true:false" @click="add_Grouping" style="cursor: pointer; margin-left: 20px;">{{is_show==true?'选择分组':'新增分组'}}</span>
			</el-form-item>
			<el-form-item  v-if="is_show" label="分组名称">
			  <el-input placeholder="请输入分组名称" v-model="formdata.group_name"></el-input>
			</el-form-item>
		      <el-form-item label="类型">
		        <el-select @change="select_change" v-model="formdata.type" placeholder="请选择类型">
		          <el-option label="单选" value="radio"></el-option>
		          <el-option label="文本" value="input"></el-option>
				  <el-option label="密文" value="password"></el-option>
		        </el-select>
		      </el-form-item>
				  <el-form-item label="变量名">
					<el-input @input="name" v-model="formdata.name" placeholder="只能输入英文或者拼音"></el-input>
				  </el-form-item>
				<el-form-item label="变量标题">
				  <el-input @input="title" placeholder="只能输入英文或者拼音" v-model="formdata.title"></el-input>
				</el-form-item>
				<el-form-item label="变量值">
				  <el-input @input="value" placeholder="请输入值"  v-model="formdata.value"></el-input>
				</el-form-item>
				<el-form-item label="变量描述">
				  <el-input @input="tip" v-model="formdata.tip"></el-input>
				</el-form-item>


		      <el-form-item>
		        <el-button type="primary" @click="dialogVisibles">{{is_type==1?'立即创建':'编辑'}}</el-button>
		        <el-button @click="dialogVisible = false">取消</el-button>
		      </el-form-item>
		    </el-form>
<!-- 		    <span slot="footer" class="dialog-footer">
		      <el-button @click="dialogVisible = false">取 消</el-button>
		      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		    </span> -->
		  </el-dialog>	  
			  
	</el-card >

</template>
<script>
  import { get_systemsetting,saveSystemsetting,getSystemsettingType } from "@/api/api.js";
  export default {
    data() {
      return {
		  is_type:'',
		  formdata:{
			  group:'',
			  group_name:'',
			  name:'',
			  title:'',
			  type:'',
			  tip:'',
			  value:'',
		  },
		is_show:false,  
		activeName:'',  
		tabs:[],
		group:'',
		dialogVisible:false,
		Systemsetting_list:[],
		data:[],
        form: {
		  
        },
		radio_list:[],
		id:'',
		options:[],
		formeditall:{}
      }
    },
    methods: {
		name(){
			this.formdata.name=this.formdata.name.replace(/[\W]/g,'')
			this.formeditall.name=this.formdata.name
		},
		title(){
			this.formdata.title=this.formdata.title.replace(/[\W]/g,'')
			this.formeditall.title=this.formdata.title
		},
		tip(){
			this.formeditall.tip=this.formdata.tip
		},
		select_change(){
			this.formeditall.type=this.formdata.type
		},
		value(){
			this.formeditall.value=this.formdata.value
		},
		bianji(index){
			this.is_type=2
			this.dialogVisible=true
			console.log(this.Systemsetting_list[index])
			this.formdata=this.Systemsetting_list[index]
			this.id=this.Systemsetting_list[index].id
			// console.log(this.form)
		},
		// 添加
		dialogVisibles(){
			console.log(this.formdata)
			if(this.is_type==1){
				this.formdata.send_type='add'
				this.save_Systemsetting(this.formdata)
			}else{
				this.formeditall.id=this.id
				this.formeditall.send_type='editall'
				this.save_Systemsetting(this.formeditall)
			}
			
			
		},
		change(e){
			console.log(e)
			this.options.forEach((item,index)=>{
				if(item.group==e){
					this.formdata.group_name=item.group_name
				}
			})
		},
		add_Grouping(){
			this.is_show=!this.is_show
		},
		// tab切换
	  handleClick(tab, event) {
		// console.log(tab, event);
		this.form={}
		this.data=[]
		this.activeName=tab.name
		this.getsystemsetting()
	  },
	  // 单选框点击
		radio_click(name,value,index,id,Changeover){
			console.log(name,value,index,id,Changeover)
			var values
			if(Changeover=="true"){
				if(index=="1"){
					values=true
				}else{
					values=false
				}
			}else{
				values=index
			}
			let data={
				id:id,
				value:values
			}
			console.log(this.data)
			if(this.data.length>0){
				this.data.forEach((item,i)=>{
					if(item.id==id){
						// item.id=id
						item.value=values
					}else{
						this.data.push(data)
					}
				})
			}else{
				this.data.push(data)
			}
			console.log(this.data)
		},	 
		 // input输入
		shuru(e,name,value,id){
			console.log(e,name,value,id)
			let data={
				id:id,
				value:e
			}
			if(this.data.length>0){
				this.data.forEach((item,index)=>{
					if(item.id==id){
						item.value=e
					}else{
						this.data.push(data)
					}
				})
			}else{
				this.data.push(data)
			}
			
			
			console.log(this.data)
		},
		// 新增
		add(){
			this.formdata={
			  group:'',
			  group_name:'',
			  name:'',
			  title:'',
			  type:'',
			  tip:'',
			  value:'',
			}
			this.dialogVisible=true
			this.is_type=1
		},
		// 获取系统设置分类
		async get_SystemsettingType(){
			let status={}
			const { data } = await getSystemsettingType(status);
			if (data.code != 200) return this.$message.error(data.data);
			console.log(data)
			this.tabs=data.data
			this.activeName=data.data[0].group
			this.options=data.data
			this.getsystemsetting()
		},
		//获取系统设置
		async getsystemsetting(){
			let status={
				group:this.activeName 
			}
			const { data } = await get_systemsetting(status);
			if (data.code != 200) return this.$message.error(data.data);
			console.log(data)
			// this.Systemsetting_list=data.data
			data.data.forEach((item,index)=>{
				if(item.type=='radio'){
					if(item.value=='false'){
						item.value='1'
						item.Changeover='true'
					}
					if(item.value=='true'){
						item.value='2'
						item.Changeover='true'
					}
				}
				this.$set(this.form,item.name,item.value)
				// this.form[item.name]=item.value
				this.Systemsetting_list=data.data
				
			})
			// console.log(this.form)
			
			// this.id=data.data.id
		},
		// 提交信息
		async save_Systemsetting(status){
			const { data } = await saveSystemsetting(status);
			if (data.code != 200) return this.$message.error(data.data);
			this.$message({
			  message: data.data,
			  type: 'success'
			});
			if(this.dialogVisible==true){
				this.get_SystemsettingType()
				this.formdata={
				  group:'',
				  group_name:'',
				  name:'',
				  title:'',
				  type:'',
				  tip:'',
				  value:'',
				}
				this.formeditall={}
			}
			
			this.dialogVisible=false
			this.getsystemsetting()
		},
      onSubmit() {
        console.log('submit!',this.form);
		this.form.send_type='edit'
		if(this.data.length>0){
			this.form.data=this.data
		}
		
		// let data={
		// 	id:this.id,
		// 	value:this.value
		// }
		// this.form.data=[]
		
		this.save_Systemsetting(this.form)
      }
    },
	mounted(){
		this.get_SystemsettingType()
		
	}
  }
</script>